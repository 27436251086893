import tpsApiInstance from "./tpsApiBase";
import {
  IDriverList,
  IDriverDetail,
  IDriverGetListTotalNumRequestParams,
  IDriverGetListRequestParams,
  IDriverGetDetailRequestParams,
  IDriverDetailUpdateRequestParams,
  IDriverCreateResponseSchema,
  IDriverDetailUpdateResponseSchema
} from './models/driver'

const DRIVER_PATH = "/driver";

export const apiDriverGetListTotalNum = async(
  pData?: IDriverGetListTotalNumRequestParams): Promise<number> => {
  let query: string = "?count=total";
  if(pData && pData.filter) query+= `&filter=${pData.filter}`;
  const res = await tpsApiInstance.get(`${DRIVER_PATH}${query}`);
  console.log("total:",res?.data[0]?.total);
  return res?.data[0]?.total;
};

export const apiDriverGetList = async(
  pData?: IDriverGetListRequestParams): Promise<IDriverList | Blob> => {
  let query: string = "?";
  if(pData && pData.limit && pData.page) query += `limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;  
  if(pData && pData.filter_brand) query+= `&filter_brand=${pData.filter_brand}`;
  if(pData && pData.filter) query+= `&filter=${pData.filter}`;
  if(pData && pData.output) {
    query += `&output=${pData.output}`;
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${DRIVER_PATH}${query}`,{responseType: 'blob'});
    return res?.data as Blob;
  }
  else {
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${DRIVER_PATH}${query}`);
    return res?.data as IDriverList;
  }
};

export const apiDriverGetDetail = async (
  pData: IDriverGetDetailRequestParams): Promise<IDriverDetail> => {
  const res = await tpsApiInstance.get(`${DRIVER_PATH}/${pData.id}`);
  //console.log(res.data[0]);
  return res?.data[0] as IDriverDetail;
};

export const apiDriverCreate = async (
  pData: IDriverDetail): Promise<IDriverCreateResponseSchema> => {
  console.log(JSON.stringify(pData));
  const res = await tpsApiInstance.post(DRIVER_PATH, pData);
  console.log(res)
  return res?.data as IDriverCreateResponseSchema;
};

export const apiDriverDetailUpdate = async (
  pData:IDriverDetailUpdateRequestParams): Promise<IDriverDetailUpdateResponseSchema> => {
  console.log(JSON.stringify(pData.data));
  const res = await tpsApiInstance.post(`${DRIVER_PATH}/${pData.id}`, pData.data);
  return res?.data as IDriverDetailUpdateResponseSchema
};


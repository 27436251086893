
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from "vue-property-decorator";
import { apiDriverGetDetail,
         apiDriverDetailUpdate,
         apiDriverCreate } from '../services/tpsApi/tpsApiDriver';
import { apiCompanyGetListName } from '../services/tpsApi/tpsApiCompany';
import { apiBrandGetListName } from '../services/tpsApi/tpsApiBrand';
import { apiReaderGetListReaderSN, apiReaderGetPlateNOList } from '../services/tpsApi/tpsApiReader'
import { IDriverDetail } from '../services/tpsApi/models/driver';
import { arrangeUpdateData } from '../services/arrangeUpdateData';
import WarningModal from '@/components/WarningModal.vue';

@Options({
  components: {
    WarningModal
  },
  emits: ["hide-form"]
})


export default class DriverForm extends Vue {
    $refs!: {
        warning: WarningModal
    }

    @Prop({ required: true }) showForm: boolean = false;

    WRITE: number | undefined = undefined; 
    originalData: IDriverDetail = {} as any;
    formData: IDriverDetail = {} as any;
    actionType: string = "";
    driverID: number = -1;
    companySelectOptions: {} = {};
    brandSelectOptions: {} = {};
    plateNOSelectOptions: {} = {};
    
    accountWarningText: string = "";
    passwordWarningText: string = "";
    permission = undefined;

    async beforeMount() {
        this.WRITE = this.$store.getters.write;
        this.permission = this.$store.getters.permission;
        console.log(this.permission != 'admin')
    }

    async mounted() {
        this.companySelectOptions = await apiCompanyGetListName();
        this.brandSelectOptions = await apiBrandGetListName();
        this.plateNOSelectOptions = await apiReaderGetPlateNOList();
    }

    async initForm(pActionType, pDriverID) {
        this.actionType = pActionType;
        this.driverID = pDriverID;
        this.accountWarningText = "";
        this.passwordWarningText = "";

        if(pDriverID) {
            this.formData = Object.assign({}, await apiDriverGetDetail({id:pDriverID}));

            this.formData["terminal_id"] = this.plateNOSelectOptions[this.formData["plate_no"]];
            this.originalData = Object.assign({}, this.formData);
        }
        else {
            this.formData = {} as any;
            //Default select first item
            this.formData['brand_id'] = Object.keys(this.brandSelectOptions)[0];
            this.formData['company_id'] = Object.keys(this.companySelectOptions)[0];
            //Default data
            this.formData["invalid"] = 0;
        }
    }

    setReaderSN(pKey) {
        this.formData["terminal_id"] = this.plateNOSelectOptions[pKey];
    }

    getWriteProp() {
        if(this.actionType === 'create') return true;
        return this.WRITE===1?true:false;
    }

    async updateDetail(pSave: boolean) {
        if(pSave) {
            !this.formData.app_account?this.accountWarningText = "帳號不能為空白":this.accountWarningText ="";
            !this.formData.password?this.passwordWarningText = "密碼不能為空白":this.passwordWarningText ="";
            
            if(this.formData.app_account && 
                this.formData.password) {
                
                //Set default modified_time & modified_user
                let dt = new Date(); 
                this.formData["modified_time"] = `${
                    (dt.getMonth()+1).toString().padStart(2, '0')}-${
                    dt.getDate().toString().padStart(2, '0')}-${
                    dt.getFullYear().toString().padStart(4, '0')} ${
                    dt.getHours().toString().padStart(2, '0')}:${
                    dt.getMinutes().toString().padStart(2, '0')}:${
                    dt.getSeconds().toString().padStart(2, '0')}`;
                this.formData["modified_user"] = this.$store.getters.currentUser;
                
                if(this.actionType == "update") {
                    let res = await apiDriverDetailUpdate({ id: this.driverID, 
                                                            data: arrangeUpdateData(this.formData,this.originalData)});
                    if(res.msg === ("ok")) this.hideForm(true);
                    else this.hideForm(false);
                }      
                else if(this.actionType == "create") {
                    try{
                        let res = await apiDriverCreate(this.formData);
                        if(res.msg === ("ok")) this.hideForm(true);
                        else this.hideForm(false);
                    }
                    catch(err) {
                        if(err?.data?.msg == "account is exist") {
                            this.accountWarningText = "此帳號已申請";
                        }
                    }
                }  
            }    
        }
        if(pSave == false) {
            this.hideForm(false);
        }
    }

    showWarningModal() {
        if(JSON.stringify(this.originalData) !== JSON.stringify(this.formData)) {
            this.$refs.warning.setModalVisible(true);
        }
        else {
            this.updateDetail(false);
        }
    }

    handleCancel() {
        this.hideForm(false);
    }

    @Emit("hide-form")
    hideForm(pSuccess) {
        if(pSuccess) return { actionType: this.actionType, alertType:"success", msg:"成功儲存" };
    }
}
